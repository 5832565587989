import Text from '../atoms/Text'
import Title from '../atoms/Title'
import React, { useState } from 'react'
import { motion } from 'framer-motion'

function Accordion({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false)

  const accordionComp = {
    style: {
      backgroundColor: '#f1f1f1',
      cursor: 'pointer',
      borderRadius: '15px',
      padding: '15px 25px',
      margin: '15px 0'
    },
    initial: { opacity: 0, y: 25 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true, margin: '-40px' },
    transition: { duration: 1, type: 'spring', bounce: 0 }
  }

  const questionComp = {
    style: {
      borderRadius: '15px'
    }
  }

  const titleComp = {
    textSize: '18px',
    baseColor: '#111',
    margin: '0',
    style: {}
  }

  const textComp = {
    baseColor: '#111',
    margin: '0',
    style: {}
  }

  return (
    <motion.div {...accordionComp} onClick={() => setIsOpen(!isOpen)}>
      <motion.div
        {...questionComp}
        initial={{ y: 0 }}
        animate={isOpen ? { y: -5 } : { y: 0 }}
        viewport={{ once: true, margin: '-40px' }}
        transition={{ duration: 1, type: 'spring', bounce: 0 }}
      >
        <Title {...titleComp}>{question}</Title>
      </motion.div>

      <motion.div
        initial={{ height: 0, opacity: 0, y: -20 }}
        animate={
          isOpen
            ? { height: 'auto', opacity: 1, y: 5 }
            : { height: 0, opacity: 0, y: -20 }
        }
        viewport={{ once: true, margin: '-40px' }}
        transition={{ duration: 1, type: 'spring', bounce: 0 }}
      >
        <Text {...textComp}>{answer}</Text>
      </motion.div>
    </motion.div>
  )
}

export default Accordion
