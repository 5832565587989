import Text from '../atoms/Text'
import Button from '../atoms/Button'
import Image from '../atoms/Image'
import TripleGrid from '../molecules/TripleGrid'
import React from 'react'

function Footer() {
  return (
    <footer>
      <TripleGrid grid="70px 100px 1fr">
        <div>
          <Image
            src="/img/logo.svg"
            alt="Produti"
            width="70px"
            height="auto"
          />
        </div>
        <div>
          <Button
            text="ENTRE EM CONTATO"
            link="https://wa.me/5563981032332?text=Ol%C3%A1+Ricardo%2C+quero+saber+mais+sobre+o+Freela+Produtivo%21"
            textSize="10px"
            color="#222"
            hoverColor="#333"
            glow={false}
          />
        </div>
        <div>
          <Text>
            As declarações feitas neste site não são promessas ou garantias
            sobre resultados futuros. Sua experiência pode variar e a Produti
            não tem responsabilidade sobre.
          </Text>
        </div>
      </TripleGrid>
    </footer>
  )
}

export default Footer
