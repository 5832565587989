import Text from '../atoms/Text'
import Button from '../atoms/Button'
import Image from '../atoms/Image'
import TripleGrid from '../molecules/TripleGrid'
import DoubleGrid from '../molecules/DoubleGrid'

function CallPrice() {
  const subOneComp = {
    baseColor: '#555  ',
    textAlign: 'center',
    textSize: '20px',
    baseWeight: '400',
    margin: '0px 0 0'
  }

  const subTwoComp = {
    baseColor: '#111',
    textAlign: 'center',
    textSize: '60px',
    baseWeight: '400',
    strong: [1, 2],
    strongWeight: '800',
    strongColor: '#111',
    margin: '0px 0 0'
  }

  const subThreeComp = {
    baseColor: '#555',
    textAlign: 'center',
    textSize: '24px',
    baseWeight: '400',
    strong: [2, 1],
    strongWeight: '800',
    strongColor: '#111',
    margin: '10px 0 10px'
  }

  const textComp = {
    baseColor: '#111',
    textAlign: 'center',
    textSize: '20px',
    strong: [1, 4],
    strongColor: '#111',
    margin: '30px 0 20px'
  }

  const buttonComp = {
    color: '#6a89f6',
    hoverColor: '#5b76df',
    link: 'https://pay.kiwify.com.br/b9TECOj',
    glow: false,
    text: 'ADQUIRIR',
    textSize: '18px',
    padding: '10px 40px'
  }

  return (
    <TripleGrid grid="150px 1fr 150px">
      <div></div>
      <div>
        <DoubleGrid grid="1fr 1fr">
          <div>
            <Image
              src="/img/mascot.svg"
              alt="Produti"
              width="250px"
              height="auto"
            />
          </div>
          <div>
            <Text {...subOneComp}>Por apenas 4x de</Text>
            <Text {...subTwoComp}>R$5,11</Text>
            <Text {...subThreeComp}>OU R$19 À VISTA</Text>
            <Image
              src="/img/payments.svg"
              alt="Produti"
              width="200px"
              height="auto"
            />
          </div>
        </DoubleGrid>
        <Text {...textComp}>
          Clique no botão abaixo e dê o primeiro passo para se tornar um
          Freelancer de sucesso agora mesmo
        </Text>
        <Button {...buttonComp} />
      </div>
      <div></div>
    </TripleGrid>
  )
}

export default CallPrice
