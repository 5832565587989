import { Component } from 'react'
import { motion } from 'framer-motion'

class Image extends Component {
  static defaultProps = {
    src: '',
    alt: '',
    width: '100%',
    height: 'auto',
    borderRadius: '0',
    boxShadow: 'none',
    objectFit: 'cover'
  }

  render() {
    const { src, alt, width, height, borderRadius, boxShadow, objectFit } =
      this.props

    const imageStyles = {
      width: width,
      height: height,
      borderRadius: borderRadius,
      boxShadow: boxShadow,
      objectFit: objectFit
    }

    const divComp = {
      initial: { opacity: 0, y: 25 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true, margin: '-40px' },
      transition: { duration: 1, type: 'spring', bounce: 0 }
    }

    return (
      <motion.div {...divComp}>
        <img src={src} alt={alt} style={imageStyles} />
      </motion.div>
    )
  }
}

export default Image
