import { Component } from 'react'
import { motion } from 'framer-motion'

class Icon extends Component {
  static defaultProps = {
    size: '50px',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.7 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"/></svg>'
  }

  render() {
    const { props } = this
    const { size, svg } = props


    function getSVG(svg) {
      var viewBox = svg.match(/viewBox="([^"]+)"/)[1]
      var viewBoxArray = viewBox.split(' ')
      var formatBox = `-10 -10 ${parseInt(viewBoxArray[2]) + 20} ${parseInt(viewBoxArray[3]) + 20}`
      var match = svg.match(/d="([^"]+)"/)
      return [formatBox, match]
    }


    const path = getSVG(svg)

    const icon = {
      hidden: {
        //fillOpacity: 0,
        strokeWidth: 20,
        scale: 1.1
      },
      visible: {
        fillOpacity: 1,
        strokeWidth: 0,
        scale: 0.9,
        opacity: 0,
        y: 25
      }
    }

    const pathComp = {
      d: path[1][1],
      stroke: '#111',
      fill: '#6a89f6',
      strokeLinecap: 'round'
    }

    const svgComp = {
      viewBox: path[0],
      width: 'auto',
      height: size,
      variants: icon,
      initial: 'visible',
      whileHover: 'hidden',
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true, margin: '-40px' },
      transition: { duration: 1, type: 'spring', bounce: 0 },
    }

    return (
      <motion.svg {...svgComp}>
        <motion.path {...pathComp} />
        <p>{path}</p>
      </motion.svg>
    )
  }
}

export default Icon
