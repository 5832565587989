import Text from '../atoms/Text'
import Title from '../atoms/Title'
import Image from '../atoms/Image'
import DoubleGrid from '../molecules/DoubleGrid'

function AboutUs() {
  const titleComp = {
    baseColor: '#111111',
    strongColor: '#5b76df',
    strong: [3, 1],
    textSize: '28px',
    textAlign: 'center'
  }

  const subtitleComp = {
    baseColor: '#111111',
    textSize: '20px',
    strongColor: '#5b76df',
    strong: [9, 6]
  }

  const nameComp = {
    baseColor: '#111111',
    strongColor: '#5b76df',
    strong: [1, 1],
    textSize: '24px',
    textAlign: 'center'
  }

  const textComp = {
    baseColor: '#111111'
  }

  const imgComp = {
    borderRadius: '20px',
    width: '70%'
  }

  return (
    <div>
      <div style={{ marginBottom: '50px' }}>
        <Title {...titleComp}>QUEM NÓS SOMOS</Title>
        <Text {...subtitleComp}>
          Somos irmãos e estudantes de administração da USP. Juntos, decidimos
          criar o Freela Produtivo, um produto para aumentar a agilidade e
          produtividade no gerenciamento de projetos, contatos com clientes e
          vida financeira.
        </Text>
      </div>
      <DoubleGrid>
        <div>
          <Image src="img/ricardo.jpg" {...imgComp} />
          <Title {...nameComp}>Ricardo Carreira</Title>
          <Text {...textComp}>
            Especialista em templates de gestão de projetos e com experiência em
            diversas empresas na área de organização, Ricardo é conhecido por
            sua habilidade em criar ferramentas de gestão eficientes e
            intuitivas, tornando o trabalho mais fácil para todos.
          </Text>
        </div>
        <div>
          <Image src="img/gabriel.jpg" {...imgComp} />
          <Title {...nameComp}>Gabriel Carreira</Title>
          <Text {...textComp}>
            Freelancer há 7 anos, especializado em motion design, Gabriel teve
            sua virada de chave nos últimos 12 meses, onde fez trabalhos para
            para grandes players como Palmeiras, Peter Jordan, Deolane e ex
            jogador Edmílson.
          </Text>
        </div>
      </DoubleGrid>
    </div>
  )
}

export default AboutUs
