import { Component } from 'react'
import { motion } from 'framer-motion'

class Button extends Component {
  /**
   * text: texto exibido no botão
   * link: link onde o botão leva
   * color: cor de fundo do botão
   * hoverColor: cor de fundo do botão quando o cursor estiver sobre ele
   * padding: padding do botão
   * radius: borda arredondada do botão
   * textColor: cor do texto do botão
   * textSize: tamanho do texto do botão
   * textWeight: peso do texto do botão
   * glow: se o botão tem sombra
   */
  static defaultProps = {
    text: 'SAIBA MAIS',
    link: '/#',
    color: '#00cc55',
    hoverColor: '#00aa33',
    padding: '10px 20px',
    radius: '5px',
    textColor: 'white',
    textSize: '12px',
    textWeight: 'bold',
    glow: true
  }

  render() {
    const { props } = this

    const divComp = {
      initial: { opacity: 0, y: 25 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true, margin: '-40px' },
      transition: { duration: 1, type: 'spring', bounce: 0 }
    }

    const buttonStyles = {
      background: props.color,
      borderRadius: props.radius,
      padding: props.padding,
      color: props.textColor,
      fontWeight: props.textWeight,
      fontSize: props.textSize,
      cursor: 'pointer',
      filter: props.glow ? `drop-shadow(0px 5px 5px ${props.color}70)` : ''
    }

    const buttonComp = {
      whileHover: {
        //scale: 1.1,
        backgroundColor: props.hoverColor,
        transition: { duration: 0.2 }
      },
      //whileTap: { scale: 0.9 },
      style: buttonStyles,
      className: 'btn'
    }

    return (
      <motion.div {...divComp}>
        <a href={props.link} style={{ textDecoration: 'none' }}>
          <motion.button {...buttonComp}>{props.text}</motion.button>
        </a>
      </motion.div>
    )
  }
}

export default Button
