import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'


function Carousel(props) {
  const images = props.imgs
  const carousel = useRef()
  const [width, setWidth] = useState(0)

  useEffect(() => {
    console.log(carousel.current)
    setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
  }, [])

  //const { props } = this

  const carouselComp = {
    ref: carousel,
    className: 'carousel',
    whileTap: { cursor: 'grabbing' },

    initial: { opacity: 0, y: 100 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true, margin: '-40px' },
    transition: { duration: 1, type: 'spring', bounce: 0 }
  }

  const innerComp = {
    className: 'inner',
    drag: 'x',
    dragConstraints: { right: 0, left: -width }
  }

  const itemComp = {
    className: 'item',
    style: {
      padding: '10px'
    }
  }

  return (
    <motion.div {...carouselComp}>
      <motion.div {...innerComp}>
        {images.map((image) => (
          <div {...itemComp}>
            <img
              style={{ pointerEvents: 'none' }}
              src={image}
              alt="texto-alt"
            />
          </div>
        ))}
      </motion.div>
    </motion.div>
  )
}

export default Carousel
