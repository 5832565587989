import Title from '../atoms/Title'
import Icon from '../atoms/Icon'
import Carousel from '../molecules/Carousel'

function TitleCarousel() {
  const title = 'VEJA O FREELA PRODUTIVO POR DENTRO'
  return (
    <div>
      <Title
        baseColor="#111"
        textSize="24px"
        textAlign="center"
        strong={[3, 2]}
        strongColor="#6a89f6"
      >
        {title}
      </Title>
      <Carousel
        imgs={[
          '/img/prints/freela.png',
          '/img/prints/hoje.png',
          '/img/prints/semana.png',
          '/img/prints/negocios.png',
          '/img/prints/atividades.png',
          '/img/prints/projetos.png',
          '/img/prints/clientes.png'
        ]}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10px'
        }}
      >
        <Icon
          size="40px"
          svg='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>'
        />
      </div>
    </div>
  )
}

export default TitleCarousel
