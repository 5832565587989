import Chargeback from '../organisms/Chargeback'
import CallPrice from '../organisms/CallPrice'
import CallToAction from '../organisms/CallToAction'
import HeadlineVideo from '../organisms/HeadlineVideo'
import TitleCarousel from '../organisms/TitleCarousel'
import TripleCards from '../organisms/TripleCards'
import AboutUs from '../organisms/AboutUs'
import Footer from '../organisms/Footer'
import QuestionAnswers from '../organisms/QuestionAnswers'
import Image from '../atoms/Image'

function LandingPage() {
  return (
    <div>
      <section>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '30px 0'
          }}
        >
          <Image
            src="/img/freela.svg"
            alt="Produti"
            width="300px"
            height="auto"
          />
        </div>

        <HeadlineVideo />
      </section>

      <section style={{ backgroundColor: '#f1f1f1' }}>
        <TripleCards />
        <div style={{ marginTop: '40px' }}>
          <CallToAction />
        </div>
      </section>

      <section>
        <Chargeback />
      </section>

      <section style={{ backgroundColor: '#f1f1f1' }}>
        <TitleCarousel />
      </section>

      <section>
        <AboutUs />
      </section>

      <section style={{ backgroundColor: '#f1f1f1' }}>
        <CallPrice />
      </section>

      <section>
        <QuestionAnswers />
      </section>

      <section style={{ backgroundColor: '#111' }}>
        <Footer />
      </section>
    </div>
  )
}

export default LandingPage

/*
git add .
git commit -m "grid icons"
git push -u origin main
*/
