import { Component } from 'react'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player/youtube'

class Youtube extends Component {
  static defaultProps = {
    embed: 'SEM_URL',
    title: 'Youtube Video'
  }

  render() {
    const { props } = this
    const embedLink = `https://www.youtube.com/embed/${props.embed}`
    const divStyle = {
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%'
    }

    const divComp = {
      initial: { opacity: 0, y: 50 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true, margin: '-40px' },
      transition: { duration: 1, type: 'spring', bounce: 0 },
      style: divStyle
    }

    const frameStyle = {
      position: 'absolute',
      top: 0,
      left: 0
    }

    const frameComp = {
      style: frameStyle,
      url: embedLink,
      width: '100%',
      height: '100%',
      light: true,
      playing: true
      // src: embedLink,
      // frameBorder: '0',
      // allow:
      //   'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
      // allowFullScreen: true
    }

    return (
      <motion.div {...divComp}>
        <ReactPlayer {...frameComp} />
        {/**/}
      </motion.div>
    )
  }
}

export default Youtube
