import { Component } from 'react'
import { motion } from 'framer-motion'

class List extends Component {
  static defaultProps = {
    list: [
      'Escreva aqui o item 1',
      'Depois o item 2 aqui',
      'E quantos mais quiser'
    ],
    iconColor: '#00cc55',
    textColor: 'white',
    textSize: '16px',
    iconSize: '24px',
    iconClass: 'fa-solid fa-check',
    iconMargin: '8px',
    lineHeight: 1.5,
    textWeight: 400
  }

  render() {
    const { props } = this

    const ulStyle = {
      listStyle: 'none'
    }

    const liStyle = {
      display: 'flex',
      alignItems: 'top',
      fontSize: props.textSize,
      lineHeight: props.lineHeight,
      color: props.textColor,
      fontWeight: props.textWeight
    }

    const liComp = {
      initial: { opacity: 0, y: 25 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true, margin: '-40px' },
      transition: { duration: 1, type: 'spring', bounce: 0 },
      style: liStyle
    }

    const iStyle = {
      fontSize: props.iconSize,
      marginRight: props.iconMargin,
      color: props.iconColor
    }

    const iComp = {
      className: props.iconClass,
      style: iStyle
    }

    return (
      <div>
        <ul style={ulStyle}>
          {props.list.map((item) => (
            <motion.li {...liComp}>
              <i {...iComp} />
              {item}
            </motion.li>
          ))}
        </ul>
      </div>
    )
  }
}

export default List
