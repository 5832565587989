import Text from '../atoms/Text'
import Title from '../atoms/Title'
import Image from '../atoms/Image'
import DoubleGrid from '../molecules/DoubleGrid'

function Chargeback() {
  const imageComp = {
    width: '80%',
    src: 'img/selo.png',
  }

  const titleComp = {
    strongColor: '#6a89f6',
    strong: [2, 3],
    textAlign: 'center',
    margin: '0',
    baseColor: '#111'
  }

  return (
    <DoubleGrid grid="250px 1fr">
      <div>
        <Image {...imageComp}
        />
      </div>
      <div>
        <Title {...titleComp}>
          OS PRIMEIROS 7 DIAS POR NOSSA CONTA
        </Title>
        <Text baseColor="#111">
          Use todas as funcionalidades do Freela Produtivo, e caso na 1º
          semana após a compra você deseje não fazer mais parte do nosso
          ecossistema, é só solicitar o estorno da sua compra em até 7 dias.
        </Text>
      </div>
    </DoubleGrid>
  )
}

export default Chargeback
