import { Component } from 'react'
import { motion } from 'framer-motion'

class Title extends Component {
  static defaultProps = {
    strong: [0, 0],
    baseColor: 'white',
    strongColor: '#00cc55',
    textSize: '28px',
    baseWeight: 600,
    strongWeight: 800,
    textAlign: 'left',
    margin: '20px 0'
  }

  render() {
    const { props } = this
    
    const baseComp = {
      style: {
        color: props.baseColor,
        fontWeight: props.baseWeight,
        fontSize: props.textSize,
        textAlign: props.textAlign,
        margin: props.margin
      }
    }

    const spanComp = {
      style: {
        fontWeight: props.strongWeight,
        color: props.strongColor
      }
    }

    const divComp = {
      initial: { opacity: 0, y: 25 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true, margin: '-40px' },
      transition: { duration: 1, type: 'spring', bounce: 0 }
    }

    const childSplit = props.children.split(' ')
    var txtOne = '',
      txtTwo = '',
      txtThree = ''

    for (var i = 0; i < childSplit.length; i++) {
      if (i < props.strong[0] - 1) {
        txtOne += `${childSplit[i]} `
      } else if (i < props.strong[0] + props.strong[1] - 1) {
        txtTwo += `${childSplit[i]} `
      } else {
        txtThree += `${childSplit[i]} `
      }
    }

    return (
      <motion.div {...divComp}>
        <h1 {...baseComp}>
          {txtOne}
          <span {...spanComp}> {txtTwo} </span>
          {txtThree}
        </h1>
      </motion.div>
    )
  }
}

export default Title
