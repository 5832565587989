import Text from '../atoms/Text'
import Button from '../atoms/Button'
import TripleGrid from '../molecules/TripleGrid'

function CallToAction() {
  const textComp = {
    baseColor: '#111',
    textAlign: 'center',
    textSize: '20px',
    strong:[1, 4],
    strongColor: '#111'
  }
  const buttonComp = {
    color: '#6a89f6',
    hoverColor: '#5b76df',
    link: 'https://pay.kiwify.com.br/b9TECOj',
    glow: false,
    text: 'ADQUIRIR',
    textSize: '18px',
    padding: '10px 40px'
  }

  return (
    <TripleGrid grid='150px 1fr 150px'>
      <div></div>
      <div>
          <Text {...textComp}>
            Clique no botão abaixo e dê o primeiro passo para se tornar um
            Freelancer de sucesso agora mesmo
          </Text>
            <Button {...buttonComp} />
      </div>
      <div></div>
    </TripleGrid>
  )
}

export default CallToAction
