import Text from '../atoms/Text'
import List from '../atoms/List'
import Title from '../atoms/Title'
import Button from '../atoms/Button'
import Youtube from '../atoms/Youtube'
import DoubleGrid from '../molecules/DoubleGrid'

function HeadlineVideo() {
  const title = 'MULTIPLIQUE AGORA SEU FATURAMENTO',
    text = `Traga agilidade e produtividade às suas atividades com uma série de templates para gerenciar projetos, contatos com clientes e vida financeira!`,
    subtext = 'Atenção: É muito importante assistir todo o vídeo para aprender como você vai multiplicar seu faturamento',
    titleComp = {
      textAlign: 'left',
      strong: [4, 1],
      strongColor: '#6a89f6',
      baseColor: '#111',
      margin: '0'
    },
    textComp = {
      strong: [9, 4],
      strongColor: '#5b76df',
      baseColor: '#111111'
    },
    subtextComp = {
      strong: [1, 1],
      strongColor: '#555',
      strongWeight: 600,
      baseColor: '#555',
      margin: '15px 0 0'
    },
    listComp = {
      list: [
        'Gestão de Projetos',
        'Prospecção de Clientes',
        'Controle Financeiro'
      ],
      iconColor: '#6a89f6',
      lineHeight: 1.7,
      textColor: '#111111',
      textWeight: 600
    },
    buttonComp = {
      color: '#6a89f6',
      hoverColor: '#5b76df',
      link: 'https://pay.kiwify.com.br/b9TECOj',
      glow: false

    }

  return (
    <DoubleGrid grid="5fr 7fr">
      <div>
        <Title {...titleComp}>{title}</Title>
        <Text {...textComp}>{text}</Text>
        <List {...listComp} />
        <Button {...buttonComp} />
      </div>
      <div>
        <Youtube embed="L6kgMZImVJ4" />
        <Text {...subtextComp}>{subtext}</Text>
      </div>
    </DoubleGrid>
  )
}

export default HeadlineVideo
