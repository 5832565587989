import { Component } from 'react'

class TripleGrid extends Component {
  static defaultProps = {
    grid: '1fr 1fr 1fr'
  }

  render() {
    const { props } = this
    const gridStyle = {
      gridTemplateColumns: props.grid
    }
    return (
      <div style={gridStyle} className="triple-grid">
        {this.props.children}
      </div>
    )
  }
}

export default TripleGrid
