import Title from '../atoms/Title'
import Accordion from '../molecules/Accordion'

function QuestionAnswers() {
  const titleComp = {
    textAlign: 'center',
    strong: [2, 1],
    strongColor: '#6a89f6',
    baseColor: '#111',
    margin: '0'
  }

  return (
    <>
      <Title {...titleComp}>PERGUNTAS FREQUENTES</Title>
      <Accordion
        question="Como funciona o Freela Produtivo?"
        answer="Depois da compra, você recebe um link para copiar o Freela Produtivo para o seu Notion. Além disso, você tem acesso à área de membros e à comunidade exclusiva"
      />
      <Accordion
        question="Eu nunca usei o Notion, posso usar o Freela Produtivo?"
        answer="Sim. Na área de membros, há um guia de vídeo completo para ajudar na sua utilização do Notion e do Freela Produtivo"
      />
      <Accordion
        question="Preciso pagar pelo Notion para usar o Freela Produtivo?"
        answer="Não, é possível usar o Freela Produtivo com o plano gratuito do Notion"
      />
    </>
  )
}

export default QuestionAnswers
