import { Component } from 'react'
import { motion } from 'framer-motion'
import Title from '../atoms/Title'
import Icon from '../atoms/Icon'

class IconCard extends Component {
  static defaultProps = {
    size: '50px',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.7 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"/></svg>',
    title: 'COMPRA SEGURA'
  }

  render() {
    const { props } = this
    const { size, svg, title } = props

    const titleComp = {
      textAlign: 'center',
      margin: '0',
      baseColor: '#111',
      textSize: '18px'
    }

    const divComp = {
      initial: { scale: 0.95, opacity: 0, y: 25 },
      whileHover: { scale: 1 },
      whileInView: { opacity: 1, y: 0 },
      viewport: { once: true, margin: '-40px' },
      transition: { duration: 1, type: 'spring', bounce: 0 },
      style: {
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '20px'
      }
    }

    const iconComp = {
      svg: svg,
      size: size
    }

    return (
      <motion.div {...divComp}>
        <div style={{marginBottom: '20px'}}>
          <Icon {...iconComp} />
        </div>
        <Title {...titleComp}>{title}</Title>
      </motion.div>
    )
  }
}

export default IconCard
